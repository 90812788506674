@charset 'UTF-8';
@import "/Users/alivevn/Website/vhost/kaza2019/src/scss/utility/_flex.scss";
@import "/Users/alivevn/Website/vhost/kaza2019/src/scss/utility/_media-queries.scss";
@import "/Users/alivevn/Website/vhost/kaza2019/src/scss/utility/_mixin.scss";


#page404 {
	.header {
		background: url(../../img/concept/bg_page.jpg) no-repeat 50% 0;
		background-size: cover;
	}
}
.page_404 {
	padding: 200px 20px;
	.page-title {
		font-size: 35px;
		font-weight: bold;
		text-align: center;
		letter-spacing: 5px;
		padding-bottom: 50px;
	}
	.taC {
		display: block;
		font-size: 16px;
		line-height: 30px;
		letter-spacing: 3px;
		text-align: center;
		margin: 20px 0 0 0;
		a {
			font-size: 16px;
			text-decoration: none;
			color: #00B0FF;
		}
	}
}